<script setup lang="ts">
import TairoCollapseBurger from "~/components/tairo-collapse-layout/TairoCollapseBurger.vue";

const props = withDefaults(
  defineProps<{
    collapse?: boolean
    horizontalScroll?: boolean
  }>(),
  {
    collapse: true,
  },
)

const route = useRoute()

const showNavBurger = computed(() => {
  return props.collapse;
})
</script>

<template>
  <div
    class="relative z-50 mb-5 flex min-h-16 items-start gap-2 pt-2"
    :class="props.horizontalScroll && 'pe-4 xl:pe-10'"
  >
    <div>
      <TairoCollapseBurger v-if="showNavBurger" class="h-14 w-14" />
    </div>

    <BaseHeading
      as="h1"
      size="2xl"
      weight="light"
      class="text-muted-800 dark:text-white md:block mt-3"
    >
      <slot name="title">{{ route.meta.title }}</slot>
    </BaseHeading>

    <div class="ms-auto"></div>
  </div>
</template>
