<script setup lang="ts">
import { useCollapse } from "~/composables/tairo/collapse";

// Imports
import categories from "~/ts/data/categories";
const { isOpen, isMobileOpen, toggle } = useCollapse();
const subscriptionAuth = useSubscriptionAuth();
const { getAll } = useTools(subscriptionAuth);
const subscription = useCurrentSubscription();

// Data
const tools = await getAll();
const menuItems = categories.map(category => ({
  category: category.category,
  name: category.name,
  icon: { name: category.icon, class: "w-5 h-5", },
  activePath: `/${category.category}`,
  children: getChildrenForCategory(category.category),
  to: category.externalLink,
}));

function getChildrenForCategory(category: string) {
  return tools.filter(tool => tool.category === category)
    .map(tool => ({
      name: tool.name,
      to: `/${tool.category}/${tool.id}`,
    }));
}
</script>

<template>
  <div
    class="dark:bg-muted-800 border-muted-200 dark:border-muted-700 fixed left-0 top-0 z-[60] flex h-full flex-col border-r bg-white transition-all duration-300"
    :class="[
      !isOpen ? 'w-[80px]' : 'w-full sm:w-[420px]',
      isMobileOpen
        ? 'translate-x-0 lg:translate-x-0'
        : '-translate-x-full lg:translate-x-0',
    ]"
  >
    <header class="w-full p-4 space-y-4">
      <div class="flex w-full lg:hidden flex-row justify-end">
        <button class="p-3" @click="toggle">
          <Icon
            name="material-symbols:cancel-outline-rounded"
            class="w-6 h-6"
          />
        </button>
      </div>

      <NuxtLink
        to="/"
        class="w-full grid place-items-center"
      >
        <img v-show="isOpen" src="../assets/logo-nmo.svg" alt="Logo NMO" />
        <img v-show="!isOpen" src="../assets/nmo-small-optimized.svg" alt="Logo NMO" />
      </NuxtLink>
    </header>

    <!--Body-->
    <div
      class="slimscroll relative w-full grow overflow-y-auto overflow-x-hidden py-6"
      :class="!isOpen ? 'px-4' : 'px-6'"
    >
      <h2
        class="text-sm text-gray-400 uppercase mb-4 pl-4"
        :class="[ !isOpen && 'invisible' ]"
      >
        Datasets
      </h2>

      <!--Menu-->
      <ul id="sidebar-menu" class="space-y-2">
        <!--Menu item-->
        <li v-for="(item, index) in menuItems" :key="index">
          <NavGroup
            v-if="item.children && item.children.length > 0"
            :item="item"
            :expanded="isOpen"
            @clicked="isOpen = true"
          />
          <NuxtLink
            v-else-if="item.to"
            :to="item.to"
            exact-active-class="!bg-primary-500/10 dark:!bg-primary-500/20 !text-primary-500 dark:!text-primary-500"
            class="nui-focus text-muted-500 dark:text-muted-400/80 hover:bg-muted-100 dark:hover:bg-muted-700/60 hover:text-muted-600 dark:hover:text-muted-200 flex cursor-pointer items-center gap-4 rounded-lg py-3 transition-colors duration-300"
            :class="!isOpen ? 'px-1 justify-center' : 'px-4'"
          >
            <Icon :name="item.icon.name" :class="item.icon.class" />
            <span
              class="whitespace-nowrap font-sans text-sm"
              :class="!isOpen ? 'hidden' : 'block'"
            >
              {{ item.name }}
            </span>

            <Icon  v-if="isOpen" class="ml-auto" name="material-symbols:open-in-new" />
          </NuxtLink>

          <div
            v-else-if="item.divider"
            class="border-muted-200 dark:border-muted-700 my-3 h-px w-full border-t"
          />

          <button
            v-else
            class="nui-focus text-muted-500 dark:text-muted-400/80 hover:bg-muted-100 dark:hover:bg-muted-700/60 hover:text-muted-600 dark:hover:text-muted-200 flex w-full cursor-pointer items-center gap-4 rounded-lg py-3 transition-colors duration-300"
            :class="!isOpen ? 'px-1 justify-center' : 'px-4'"
            @click="item.click"
          >
            <Icon :name="item.icon.name" :class="item.icon.class" />
            <span
              class="whitespace-nowrap font-sans text-sm"
              :class="!isOpen ? 'hidden' : 'block'"
            >
              {{ item.name }}
            </span>
          </button>
        </li>
      </ul>
    </div>

    <footer
      class="py-6"
      :class="!isOpen ? 'px-4' : 'px-6'"
    >
<!--      <h2-->
<!--        class="text-sm text-gray-400 uppercase mb-4 pl-4"-->
<!--        :class="[ !isOpen && 'invisible' ]"-->
<!--      >-->
<!--        Abonnement-->
<!--      </h2>-->

      <NuxtLink
        v-if="subscription === null"
        to="/subscription/activate"
        exact-active-class="!bg-primary-500/10 dark:!bg-primary-500/20 !text-primary-500 dark:!text-primary-500"
        class="nui-focus text-muted-500 dark:text-muted-400/80 hover:bg-muted-100 dark:hover:bg-muted-700/60 hover:text-muted-600 dark:hover:text-muted-200 flex cursor-pointer items-center gap-4 rounded-lg py-3 transition-colors duration-300"
        :class="!isOpen ? 'px-1 justify-center' : 'px-4'"
      >
        <Icon
          name="material-symbols:lock-open-outline"
          class="item.icon.class"
        />
        <span
          class="whitespace-nowrap font-sans text-sm"
          :class="!isOpen ? 'hidden' : 'block'"
        >
          Activeer abonnement
        </span>
      </NuxtLink>

      <NuxtLink
        v-else
        to="/subscription/overview"
        exact-active-class="!bg-primary-500/10 dark:!bg-primary-500/20 !text-primary-500 dark:!text-primary-500"
        class="nui-focus text-muted-500 dark:text-muted-400/80 hover:bg-muted-100 dark:hover:bg-muted-700/60 hover:text-muted-600 dark:hover:text-muted-200 flex cursor-pointer items-center gap-4 rounded-lg py-3 transition-colors duration-300"
        :class="!isOpen ? 'px-1 justify-center' : 'px-4'"
      >
        <Icon
          name="material-symbols:check-circle-outline-rounded"
          class="w-5 h-5"
        />
        <span
          class="whitespace-nowrap font-sans text-sm"
          :class="!isOpen ? 'hidden' : 'block'"
        >
          Bekijk abonnement
        </span>
      </NuxtLink>

      <NuxtLink
        to="/publicatie-regels"
        exact-active-class="!bg-primary-500/10 dark:!bg-primary-500/20 !text-primary-500 dark:!text-primary-500"
        class="nui-focus text-muted-500 dark:text-muted-400/80 hover:bg-muted-100 dark:hover:bg-muted-700/60 hover:text-muted-600 dark:hover:text-muted-200 flex cursor-pointer items-center gap-4 rounded-lg py-3 transition-colors duration-300"
        :class="!isOpen ? 'px-1 justify-center' : 'px-4'"
      >
        <Icon
          name="material-symbols:info-outline"
          class="w-5 h-5"
        />
        <span
          class="whitespace-nowrap font-sans text-sm"
          :class="!isOpen ? 'hidden' : 'block'"
        >
          Publicatie regels
        </span>
      </NuxtLink>
    </footer>
  </div>
</template>
